import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Divider, Grid, Typography } from '@mui/material';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { formatTimestampToSpanish } from '../utils/formatters';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DOMPurify from 'dompurify';


const News = ({ keycloak, news }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const { trackPageView } = useMatomo();

    const onLoad = () => {
        document.title = news.find(n => n.slug === location.pathname.split("/").pop()) ? news.find(n => n.slug === location.pathname.split("/").pop()).title + " | RedFID" : "Página no encontrada | RedFID";
        trackPageView();
        window.scrollTo(0, 0);
        return <></>;
    }

    return (
        (keycloak.didInitialize && news) &&
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: "center" }}>
            {onLoad()}
            <Header keycloak={keycloak} />
            <Grid
                component="div"
                sx={{
                    flexGrow: 1,
                    maxWidth: '940px',
                    margin: "30px 20px",
                    width: "100%"
                }}
            >
                <Grid container spacing={1} display={"flex"} justifyContent={"space-between"} alignItems={"start"}>
                    <Grid item xs={12} md={9} display={"flex"} justifyContent={"center"}>
                        <Grid container spacing={1} p={"0 20px"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                            {
                                (news.find(n => n.slug === location.pathname.split("/").pop()) && news.find(n => n.slug === location.pathname.split("/").pop()).external_url === "") ?
                                    <>
                                        <Grid item xs={12}>
                                            <Typography onClick={() => navigate("/")} style={{ display: 'flex', alignItems: 'center', color: '#40b4ba', 'width': 'fit-content' }}>
                                                <ArrowBackIcon sx={{ fontSize: '1em' }} variant="back" />
                                                <Typography variant="back">Volver al Home</Typography>
                                            </Typography>
                                            <Typography mb={"10px"} variant='title' fontSize={{ "xs": "1.2em", "md": "1.6em" }} textAlign={"justify"}>{news.find(n => n.slug === location.pathname.split("/").pop()).title}</Typography>
                                            <Typography mb={"20px"} variant='p' textAlign={"right"}>
                                                {news.find(n => n.slug === location.pathname.split("/").pop()).author}
                                                {" — "}
                                                {formatTimestampToSpanish(news.find(n => n.slug === location.pathname.split("/").pop()).start_timestamp)}
                                            </Typography>
                                        </Grid>
                                        {news.find(n => n.slug === location.pathname.split("/").pop()).content.map((c, index) => {
                                            return (
                                                <Grid item xs={12} display={"flex"} justifyContent={"center"} key={index}>
                                                    {(() => {
                                                        switch (c.type) {
                                                            case "title":
                                                                return <Typography variant='title-small' mb={"20px"}>{c.block}</Typography>
                                                            case "subtitle":
                                                                return <Typography variant='main-subtitle' mb={"20px"}>{c.block}</Typography>
                                                            case "paragraph":
                                                                return <Typography variant='p' mb={"20px"} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(c.block) }} />
                                                            case "image":
                                                                return <img src={process.env.REACT_APP_API_URL + "/get_news_image/" + news.find(n => n.slug === location.pathname.split("/").pop()).id + "/" + c.image} alt={news.find(n => n.slug === location.pathname.split("/").pop()).title} style={{ maxHeight: "400px", maxWidth: "100%", marginBottom: "20px" }} />
                                                            default:
                                                                return <></>
                                                        }
                                                    })()}
                                                </Grid>
                                            )
                                        })}
                                    </>
                                    :
                                    <Grid item xs={12} flexDirection={"column"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                        <Typography textAlign={"center"} variant='title' mb={"20px"}>Página no encontrada</Typography>
                                        <Typography textAlign={"center"} onClick={() => { keycloak.didInitialize && navigate("/") }} variant='a'>Volver al Home</Typography>
                                    </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} display={"flex"} justifyContent={"center"} overflow={"auto"} maxHeight={"700px"}>
                        <Grid container spacing={1} p={"0 20px"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                                <Typography variant='title' m={"20px 0"} textAlign={"center"} fontSize={"1em"}>Otras noticias</Typography>
                            </Grid>
                            {news.filter(n => n.slug !== location.pathname.split("/").pop()).map((n, index) => {
                                return (
                                    <Grid item xs={12} key={index}>
                                        <Typography textAlign={"justify"} fontSize={"0.75em"} onClick={() => n.external_url !== "" ? window.open(n.external_url, '_blank') : navigate("/events/" + n.slug)} variant='a'>{n.title}</Typography>
                                        <Typography mt={"10px"} textAlign={"right"} fontSize={"0.6em"}>{formatTimestampToSpanish(n.start_timestamp)}</Typography>
                                        <Divider />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </Grid>
    );
};

export default News;
